/* social media icon */
.social-icon {
  &.dark {
    a {
      color: #232323;
    }
  }
  &.light {
    a {
      color: #fff;
    }
  }
  &.xs,
  &.sm,
  &.md,
  &.lg,
  &.xl {
    list-style: none;
    padding: 0;
    li {
      display: inline-block;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      display: inline-block;
    }
  }
  &.xs {
    li {
      margin: 0 2px;
    }
    a {
      font-size: 14px;
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }
  &.sm {
    li {
      margin: 0 5px;
    }
    a {
      font-size: 16px;
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }
  &.md {
    li {
      margin: 0 8px;
    }
    a {
      font-size: 20px;
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
  }
  &.lg {
    li {
      margin-left: 10px;
      margin-right: 10px;
    }
    a {
      font-size: 24px;
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
  }
  &.xl {
    li {
      margin: 0 12px;
    }
    a {
      font-size: 30px;
      width: 70px;
      height: 70px;
      line-height: 70px;
    }
  }
}
// Social Icon Style 01
.social-icon-style-01 {
  display: flex;
  align-items: center;
  &.dark {
    a {
      color: #232323;
      text-decoration: none;
      transition: var(--default-transition);
      &:hover {
        color: var(--social-icon-color);
      }
    }
  }
  &.light {
    a {
      color: white;
      text-decoration: none;
      transition: var(--default-transition);
      &:hover {
        color: var(--social-icon-color);
      }
    }
  }
}
// Social Icon Style 02
.social-icon-style-02 {
  display: flex;
  align-items: center;
  &.dark,
  &.light {
    a {
      transition: var(--default-transition);
      &:hover {
        transform: translateY(-8px);
        color: var(--social-icon-color);
      }
    }
  }
}
// Social Icon Style 03
.social-icon-style-03 {
  display: flex;
  align-items: center;
  a {
    background-color: var(--social-icon-color);
    transition: var(--default-transition);
    &:hover {
      border-radius: 50%;
    }
  }
}
// Social Icon Style 04
.social-icon-style-04 {
  display: flex;
  align-items: center;
  a {
    background-color: var(--social-icon-color);
    transition: var(--default-transition);
    border-radius: 50%;
    &:hover {
      color: var(--social-icon-color);
      background-color: #fff;
      transform: translate3d(0, -3px, 0);
      box-shadow: 0 0 30px rgb(23 23 23 / 25%);
    }
  }
}
// Social Icon Style 05
.social-icon-style-05 {
  display: flex;
  align-items: center;
  a {
    text-align: center;
    display: inline-block;
    border-radius: 100%;
    position: relative;
    color: #232323;
    text-align: center;
    z-index: 1;
    font-size: 24px;
    width: 60px;
    height: 60px;
    span {
      border-radius: 0;
      display: block;
      height: 0;
      left: 50%;
      margin: 0;
      position: absolute;
      top: 50%;
      transition: var(--default-transition);
      width: 0;
      z-index: -1;
      background-color: var(--social-icon-color);
    }
    &:hover {
      color: #fff;
      span {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        top: 0;
        left: 0;
      }
    }
  }
}
// Social Icon Style 06
.social-icon-style-06 {
  display: flex;
  align-items: center;
  a {
    transition: var(--default-transition);
    border-radius: 0;
    &:hover {
      color: #fff;
      background-color: var(--social-icon-color);
      border-radius: 50%;
    }
  }
}
// Social Icon Style 07
.social-icon-style-07 {
  display: flex;
  align-items: center;
  &.dark,
  &.light {
    a {
      color: var(--social-icon-color);
      transition: var(--default-transition);
    }
  }
  &.dark {
    a {
      &:hover {
        color: #232323;
      }
    }
  }
  &.light {
    a {
      &:hover {
        color: #fff;
      }
    }
  }
}
// Social Icon Style 08
.social-icon-style-08 {
  display: flex;
  align-items: center;
  width: 100%;
  &.dark,
  &.light {
    a {
      color: var(--social-icon-color);
      display: inline-block;
      height: auto;
      width: auto;
      padding: 0 24px;
      position: relative;
      overflow: hidden;
      text-decoration: none;
      transition: 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275) all;
      .brand-icon {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 100%;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition-duration: 0.3s;
        transition: 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275) all;
      }
      &:hover {
        .brand-icon {
          top: 0;
          transition-duration: 0.3s;
        }
        >span.brand-label {
          top: -50px;
          transition: var(--default-transition);
          transition-duration: 350ms;
        }
      }
      >span {
        font-family: var(--alt-font);
        font-size: 20px;
        line-height: 28px;
        line-height: inherit;
        font-weight: 500;
        top: 0;
        position: relative;
        transition: var(--default-transition);
        transition-duration: 350ms;
        transition: 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275) all;
      }
    }
  }
}
// Social Icon Style 09
.social-icon-style-09 {
  display: flex;
  align-items: center;
  a {
    text-align: center;
    display: inline-block;
    border-radius: 100%;
    color: #232323;
    position: relative;
    text-align: center;
    transition: var(--default-transition);
    &:hover {
      color: white;
      background-color: var(--social-icon-color);
      &:after {
        opacity: 0.4;
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform: scale(0.8);
      border: 1px solid;
      border-radius: 100%;
      opacity: 0;
      border-color: var(--social-icon-color);
      color: var(--social-icon-color);
      transition: var(--default-transition);
      transition-duration: 0.5s;
    }
  }
}
// Social Icon Style 10
.social-icon-style-10 {
  display: flex;
  align-items: center;
  &.dark,
  &.light {
    a {
      display: inline-block;
      position: relative;
      z-index: 3;
      overflow: hidden;
      text-align: center;
      font-size: 24px;
      width: 60px;
      height: 60px;
      color: var(--social-icon-color);
      transition: 0.3s ease-in-out;
      &:hover {
        color: #fff;
        >span {
          background-color: var(--social-icon-color);
          top: 0;
          z-index: -1;
        }
      }
      >span {
        position: absolute;
        top: -100%;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        transition: var(--default-transition);
        transition-duration: 0.5s;
        transition: 0.3s ease-in-out;
      }
    }
  }
  &.dark {
    a {
      color: #000;
    }
  }
  &.light {
    a {
      &:hover {
        color: #fff;
      }
    }
  }
}
// Social Icon Style 11
.social-icon-style-11 {
  display: flex;
  align-items: center;
  @media (max-width:var(--md)) {
    width: 80%;
    justify-content: center;
    display: inline-block;
  }
  li {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }
  &.dark,
  &.light {
    a {
      display: inline-block;
      vertical-align: middle;
      height: 160px;
      width: 160px;
      position: relative;
      margin: 0 15px;
      margin-bottom: 1rem !important;
      transition: var(--default-transition);
      color: #828282;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        outline: 0;
        outline-offset: 0;
        color: #0038e3;
        .social-front {
          transition-duration: 0.3s;
          background-color: #fff;
          text-align: center;
          box-shadow: 0 0 35px rgba(23, 23, 23, 0.06);
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        .top-left {
          top: 0;
          left: 0;
        }
        .top {
          top: 0;
        }
        .top-right {
          top: 0;
          left: 60px;
        }
      }
    }
    .social-back {
      background-color: var(--social-icon-color);
      position: relative;
      height: 140px;
      width: 140px;
      padding: 30px;
      top: 30px;
      left: 30px;
      line-height: 2;
      span {
        position: absolute;
        bottom: 3px;
        right: 30px;
        text-transform: uppercase;
        color: #fff;
        font-size: 11px;
        line-height: 14px;
      }
    }
    .social-front {
      background-color: #fff;
      text-align: center;
      transition-duration: 0.3s;
      box-shadow: 0 0 35px rgb(23 23 23 / 6%);
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 140px;
      width: 140px;
      padding: 30px;
      position: absolute;
      top: 30px;
      left: 30px;
      i {
        color: var(--social-icon-color);
        display: block;
        margin: 0 0 20px 0;
        font-size: 28px;
        font-weight: 400;
        display: inline-block;
        line-height: 1;
      }
      span {
        display: block;
        color: #232323;
        font-size: 12px;
        line-height: 16px;
        font-family: var(--alt-font);
        text-transform: uppercase;
      }
    }
  }
}
// Social Icon Style 12
.social-icon-style-12 {
  display: flex;
  align-items: center;
  a {
    transition: var(--default-transition);
    i {
      color: var(--social-icon-color);
    }
    &:hover {
      opacity: 0.5;
    }
  }
}