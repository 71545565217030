// .colorful-page {
//   width:100%;
//   height:100%;
//   color:black;
//   font-weight:bold;
//   animation: myanimation 10s infinite;
// }
  
// @keyframes myanimation {
//   0% {background-color: #0038e3;}
//   25%{background-color:#ff6737;}
//   50%{background-color:#25b15f;}
//   75%{background-color:#cc2d92;}
//   100% {background-color: #0038e3;}
// }