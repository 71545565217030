// .quantity {
//     @apply inline-block relative align-middle;
// }
// .qty-btn {
//     @apply w-[22px] h-[19px] leading-[19px] border-0 p-0 m-0 absolute bg-transparent;
//     border-left: 1px solid #e8e8e8;

//     &:focus {
//         @apply border-none;
//         border-left: 1px solid #e8e8e8;
//     }
// }
// .qty-plus {
//     @apply top-[3px] right-[1px] h-5 pl-[3px] rounded-none w-[22px] leading-5;
//     border-bottom: 1px solid #e8e8e8;
//     &:focus {
//         @apply border-none;
//         border-bottom: 1px solid #e8e8e8;
//         border-left: 1px solid #e8e8e8;
//     }
// }
// .qty-minus {
//     @apply right-[1px] top-5 pl-[2px] rounded-none;
// }
// .qty-text {
//     @apply m-0 py-0 pr-6 pl-0 leading-10 rounded-none border border-[#dfdfdf] text-center w-[65px];
// }
// .coupon-code-panel {
//     @apply relative;
//     input {
//         @apply m-0 border-none w-full relative;
//         border: 1px dashed #e4e4e4;
//         padding: 10px 70px 11px 45px;
//     }
//     &:before {
//         @apply absolute left-5 z-[2] leading-[44px];
//         content: "\e8bf";
//         font-family: feather;
//     }
//     .apply-coupon-btn {
//         @apply absolute right-[10px] top-1/2 text-darkgray font-medium text-xs leading-5 capitalize;
//         transform: translateY(-50%);
//     }
// }
// .cart-btn {
//     @apply relative text-xs leading-5 h-[2px] bottom-0 left-1/2 bg-black;
//     &:after {
//         @apply absolute w-11/12;
//         content: "";
//         transform: translateX(-50%);
//     }
// }
// .total-price-table td,
// .total-price-table th {
//     @apply border-none py-[20px];
//     border-bottom: 1px solid #e4e4e4;
// }
// .total-price-table tr:last-child td,
// .total-price-table tr:last-child th {
//     @apply border-none;
// }
// .total-price-table {
//     .calculate-shipping {
//         th {
//             .calculate-shipping-title {
//                 @apply relative text-[#212529];

//                 &:after {
//                     @apply absolute right-0 top-0 font-bold;
//                     font-family: feather;
//                     content: "\e842";
//                 }
//                 &:hover {
//                     @apply outline-0 text-[#212529];
//                 }
//             }
//         }
//     }
// }
// .price-filter {
//     &.ui-widget {
//         &.ui-widget-content {
//             @apply rounded-none bg-[#dfdfdf] h-[5px];
//             border: 1px solid #fff;
//             margin: 25px 3px 30px 8px;
//         }
//     }
//     .ui-slider-range {
//         @apply bg-darkgray h-[3px];
//     }
//     .ui-slider-handle {
//         @apply bg-[#fff] w-[13px] h-[13px] top-[-5px] rounded-full outline-0;
//         border: 1px solid #bbb;
//         cursor: ew-resize;
//     }
// }
// .price-filter-details {
//     .btn-filter {
//         @apply text-[10px] text-[#101010]  bg-transparent py-[1px] px-3 outline-none font-medium text-center uppercase leading-[19px];
//         float: right;
//         text-decoration: none;
//         border: 1px solid #d6d6d6;
//         &:hover {
//             @apply text-white bg-darkgray;
//             border: 1px solid #232323;
//         }
//     }
//     .price-filter-amount {
//         .price-amount {
//             @apply border-none p-0 max-w-[115px] bg-transparent;
//         }
//     }
// }

// // cart

// .cart-products {
//     margin-bottom: 60px;

//     width: 100%;
//     margin-bottom: 1rem;
//     color: #212529;
//     vertical-align: top;
//     border-color: #dee2e6;

//     caption-side: bottom;
//     border-collapse: collapse;

//     @screen md {
//         margin-bottom: 40px;
//     }

//     thead {
//         vertical-align: bottom;
//         border-color: inherit;
//         border-style: solid;
//         border-width: 0;

//         @screen md {
//             display: none;
//         }

//         tr {
//             border-color: inherit;
//             border-style: solid;
//             border-width: 0;

//             th {
//                 border-top: 0;
//                 border-bottom: 1px solid #e4e4e4 !important;
//                 padding: 15px 10px;
//                 font-weight: 500;
//                 text-transform: capitalize;
//                 font-family: Poppins, sans-serif;
//             }
//         }
//     }

//     tbody {
//         vertical-align: inherit;

//         .product {
//             border-color: inherit;
//             border-style: solid;
//             border-width: 0;

//             @screen md {
//                 border-bottom: 1px solid #e8e8e8;
//                 position: relative;
//                 display: block;
//                 padding-left: 95px;
//                 padding-bottom: 20px;
//                 background: 0 0;
//                 margin-bottom: 20px;
//                 overflow: hidden;
//             }

//             .product-remove {
//                 width: 25px;
//                 color: #828282;
//                 vertical-align: middle;
//                 border-bottom: 1px solid #e4e4e4;
//                 border-top: 0;
//                 padding: 25px 10px;
//                 line-height: 28px;

//                 @screen md {
//                     width: auto;
//                     position: absolute;
//                     right: 0;
//                     margin: 0;
//                     padding: 0;
//                     text-align: right;
//                     top: 0;
//                     z-index: 11;
//                     border: none;

//                     &::before {
//                         content: attr(data-title);
//                         font-weight: 500;
//                         float: left;
//                         display: block;
//                         color: #232323;
//                         font-family: Poppins, sans-serif;
//                         font-size: 12px;
//                     }
//                 }

//                 span {
//                     font-size: 18px;
//                     line-height: 22px;

//                     @screen md {
//                         font-size: 16px;
//                         line-height: 22px;
//                     }
//                 }
//             }

//             .product-thumbnail {
//                 width: 110px;
//                 color: #828282;
//                 vertical-align: middle;
//                 border-bottom: 1px solid #e4e4e4;
//                 border-top: 0;
//                 padding: 25px 10px;
//                 line-height: 28px;

//                 @screen md {
//                     position: absolute;
//                     left: 0;
//                     width: 80px;
//                     display: inline-block;
//                     top: 0;
//                     overflow: hidden;
//                     text-align: left;
//                     border: none;
//                     margin-bottom: 10px;
//                     padding: 0;

//                     &::before {
//                         content: attr(data-title);
//                         font-weight: 500;
//                         float: left;
//                         display: block;
//                         color: #232323;
//                         font-family: Poppins, sans-serif;
//                         font-size: 12px;
//                     }
//                 }

//                 a {
//                     .cart-product-image {
//                         max-width: 75px;
//                         height: auto;
//                     }
//                 }
//             }

//             .product-name {
//                 color: #828282;
//                 vertical-align: middle;
//                 border-bottom: 1px solid #e4e4e4;
//                 border-top: 0;
//                 padding: 25px 10px;
//                 line-height: 28px;

//                 @screen md {
//                     text-align: left;
//                     padding-right: 25px;
//                     display: block;
//                     border: none;
//                     margin-bottom: 10px;
//                     padding: 0;

//                     &::before {
//                         content: attr(data-title);
//                         font-weight: 500;
//                         float: left;
//                         display: block;
//                         color: #232323;
//                         font-family: Poppins, sans-serif;
//                         font-size: 12px;
//                     }
//                 }

//                 a {
//                     text-decoration: none;
//                     color: #828282;
//                 }

//                 span {
//                     float: left;
//                     width: 100%;

//                     @screen md {
//                         float: none;
//                         display: block;
//                     }
//                 }
//             }

//             .product-price {
//                 color: #828282;
//                 vertical-align: middle;
//                 border-bottom: 1px solid #e4e4e4;
//                 border-top: 0;
//                 padding: 25px 10px;
//                 line-height: 28px;

//                 @screen md {
//                     display: block;
//                     border: none;
//                     text-align: right;
//                     margin-bottom: 10px;
//                     padding: 0;

//                     &::before {
//                         content: attr(data-title);
//                         font-weight: 500;
//                         float: left;
//                         display: block;
//                         color: #232323;
//                         font-family: Poppins, sans-serif;
//                         font-size: 12px;
//                     }
//                 }
//             }

//             .product-quantity {
//                 color: #828282;
//                 vertical-align: middle;
//                 border-bottom: 1px solid #e4e4e4;
//                 border-top: 0;
//                 padding: 25px 10px;
//                 line-height: 28px;

//                 @screen md {
//                     display: block;
//                     border: none;
//                     text-align: right;
//                     margin-bottom: 10px;
//                     padding: 0;

//                     &::before {
//                         content: attr(data-title);
//                         font-weight: 500;
//                         float: left;
//                         display: block;
//                         color: #232323;
//                         font-family: Poppins, sans-serif;
//                         font-size: 12px;
//                     }
//                 }

//                 .quantity {
//                     position: relative;
//                     display: inline-block;
//                     vertical-align: middle;
//                     .screen-reader-text {
//                         clip: rect(1px, 1px, 1px, 1px);
//                         position: absolute !important;
//                         height: 1px;
//                         width: 1px;
//                         overflow: hidden;
//                     }
//                     .qty-minus {
//                         cursor: pointer;
//                         right: 1px;
//                         top: 20px;
//                         padding-left: 2px;
//                         border-radius: 0;
//                         width: 22px;
//                         height: 19px;
//                         line-height: 19px;
//                         border: 0;
//                         border-left: 1px solid #e8e8e8;
//                         padding: 0;
//                         margin: 0;
//                         position: absolute;
//                         background: 0 0;
//                     }
//                     .qty-text {
//                         margin: 0;
//                         padding: 0 24px 0 0;
//                         width: 65px;
//                         line-height: 40px;
//                         height: 40px;
//                         border-radius: 0;
//                         outline: 0;
//                         text-align: center;
//                         -webkit-appearance: textfield;
//                     }
//                     .qty-plus {
//                         top: 1px;
//                         right: 1px;
//                         height: 20px;
//                         line-height: 20px;
//                         padding-left: 1px;
//                         border-radius: 0;
//                         width: 22px;
//                         height: 19px;
//                         line-height: 19px;
//                         border: 0;
//                         border-left: 1px solid #e8e8e8;
//                         border-bottom: 1px solid #e8e8e8;
//                         padding: 0;
//                         margin: 0;
//                         position: absolute;
//                         background: 0 0;
//                     }
//                 }
//             }

//             .product-subtotal {
//                 color: #828282;
//                 vertical-align: middle;
//                 border-bottom: 1px solid #e4e4e4;
//                 border-top: 0;
//                 padding: 25px 10px;
//                 line-height: 28px;

//                 @screen md {
//                     display: block;
//                     border: none;
//                     text-align: right;
//                     padding: 0;
//                     margin: 0;

//                     &::before {
//                         content: attr(data-title);
//                         font-weight: 500;
//                         float: left;
//                         display: block;
//                         color: #232323;
//                         font-family: Poppins, sans-serif;
//                         font-size: 12px;
//                     }
//                 }
//             }
//         }
//     }
// }

// .cart-dropdown {
//     select {
//         background-image: url("../img/down-arrow.png");
//         background-position: right 14px center;
//         background-repeat: no-repeat;
//         background-size: 10px;
//         border-radius: 0;
//         -webkit-appearance: none;
//         -moz-appearance: none;
//         appearance: none;
//         line-height: initial;
//         cursor: pointer;
//         &:focus-within {
//             outline: none !important;
//         }
//     }
// }

// // Checkout Page
// .checkout-page {
//     select {
//         background-image: url("../img/down-arrow.png");
//         background-position: right 14px center;
//         background-repeat: no-repeat;
//         background-size: 10px;
//         border-radius: 0;
//         -webkit-appearance: none;
//         -moz-appearance: none;
//         appearance: none;
//         cursor: pointer;
//         &:focus-within {
//             outline: none !important;
//         }
//     }

//     .checkout-accordion .accordion-button {
//         padding: 0;
//         background: transparent;
//         border: transparent;
      
//         &:after {
//           background: transparent;
//           content: '';
//         }

//         .accordion-toggle {
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//             display: inline-block;
//         }
//     }
    
//     .checkout-accordion .accordion-item {
//     background: transparent;
//     border: transparent; 
//     }

//     .accordion-header {
//     label {
//         color: #828282;
//     }
//     }
// }
