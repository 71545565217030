// .fashionshop-shopwide {
//     .filter-menu {
//         span {
//             &:hover {
//                 border-bottom-width: 2px;
//             }
//         }

//         .active {
//             span {
//                 border-bottom-width: 2px;
//             }
//         }
//     }

//     .grid-wrapper {
//         ul {
//             li {
//                 .product-box {
//                     .product-image {
//                         @screen xs {
//                             border-radius: 0 !important;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// .fashionshop-footer {
//     >div {
//         padding: 5% 0;

//         @screen xs {
//             padding: 50px 0;
//         }

//         p {
//             a {
//                 font-weight: 400;
//             }
//         }
//     }
// }

// .fashion-shop-swiper {
//     .custom-pagination {
//         bottom: 80px;

//         .swiper-pagination-bullet {
//             font-size: 15px;
//             border: 0;
//             opacity: 0.5;
//             color: #232323;
//             font-family: Poppins, sans-serif;
//             line-height: 20px;
//             font-size: 15px;
//             border-radius: 0;
//             margin-left: 15px;
//             margin-right: 15px;
//             border-bottom: 2px solid transparent;

//             &.swiper-pagination-bullet-active {
//                 color: black;
//                 opacity: 1;
//                 border-bottom: 2px solid #232323;
//             }
//         }
//     }
// }