// // Furniture Shop Page
// .furniture-shop {
//   .small-circle {
//     @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;

//     .element-one {
//       @apply rounded-full;
//       background: rgba(173, 157, 148, 0.1);
//       width: 650px;
//       height: 650px;

//       @screen lg {
//         height: 550px;
//         width: 550px;
//       }

//       @screen sm {
//         height: 400px;
//         width: 400px;
//       }

//       @screen xs {
//         height: 300px;
//         width: 300px;
//       }
//     }
//   }

//   .big-circle {
//     @apply rounded-full absolute;
//     top: -100px;
//     left: 60%;
//     border-radius: 100%;
//     white-space: nowrap;
//     min-height: 1500px;
//     min-width: 1500px;
//     max-height: 1500px;
//     max-width: 1500px;
//     transition: var(--default-transition);

//     @screen sm {
//       @apply -left-[6%] top-[65%] right-0;
//     }
//   }
// }

// // Shop Products
// .shop-category {
//   .shop-product {
//     .shop-product-image {
//       transition: all .3s ease-in-out;
//       a {
//         opacity: 0;
//         transform: scale(1, 1) translate(-50%, -50%);
//         transition: var(--default-transition);
//         top: 50%;
//         left: 50%;
//       }

//       img {
//         transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
//       }
//     }
//   }

//   &:hover {
//     .shop-product {
//       .shop-product-image {
//         transform: scale(0.9);

//         a {
//           opacity: 1;
//           transform: scale(1.1, 1.1) translate(-50%, -50%);
//           box-shadow: 0 0 25px rgb(0 0 0 / 8%);
//         }

//         img {
//           opacity: 0.4 !important;
//         }
//       }
//     }
//   }
// }

// // Swiper Pagination
// .furniture-shop {
//   .swiper-pagination {
//     .swiper-pagination-bullet {
//       @apply opacity-30 bg-darkgray border-transparent h-[10px] w-[10px] border-[2px];

//       &.swiper-pagination-bullet-active {
//         @apply opacity-100;
//       }
//     }
//   }

//   .swiper-horizontal {
//     >.swiper-pagination-bullets {
//       bottom: 30px !important;

//       @screen lg {
//         bottom: 60px !important;
//       }

//       @screen md {
//         @media screen and (orientation: landscape) {
//           bottom: 0 !important;
//         }
//       }
//     }
//   }
// }